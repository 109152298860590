@import 'main.less';
@import 'base/base.less';
@import 'base/form.less';
@import 'base/table.less';
@import 'base/typography.less';
@import 'mixins/box.less';
@import 'mixins/typography.less';
@import 'utilities/content.less';
@import 'utilities/image.less';
@import 'utilities/layout.less';
@import 'utilities/text.less';
@import 'utilities/visibility.less';

// Add some padding & styles to the tinyMCE editor window
.mce-content-body {
     padding: 15px;
    .u-content();
}
