.u-container {
    @padding: @gutter;
    max-width: @max-width;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: @max-width + (@padding * 2)) {
        padding-left: @padding;
        padding-right: @padding;
    }
}

.u-clear {
    clear:both;
}

// Clear floats like a boss

.u-clearFix {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

.u-block {
    display: block;
}

.u-inlineBlock {
    display: inline-block;
}

.u-inline {
    display: inline;
}

.u-float {
    &--left {
        float: left;
    }
    &--right {
        float: right;
    }
}


// Spacer

// Handy spacer class for adding space between items
.u-spacer(@space: 30px; @space-ratio: 2) {
    display: block;
    height: @space;
    width: @space;
    visibility: hidden;

    &--xs {
        @size: @space / (2 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--sm {
        @size: @space / (1 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--lg {
        @size: @space * (@space-ratio * 1);
        height: @size;
        width: @size;
    }

    &--xl {
        @size: @space * (@space-ratio * 2);
        height: @size;
        width: @size;
    }

    &--xxl {
        @size: @space * (@space-ratio * 3);
        height: @size;
        width: @size;
    }
}

hr.u-spacer {
    border: none;
    margin: 0;
}
