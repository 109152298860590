.typographyColor(@color; @background: #eee) {

    color: @color;

    p {
        color: @color;
    }

    h1,h2,h3,h4,h5,h6 {

        color: @color;

    }

    a {

        color: @color;

    }

}
