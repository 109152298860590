a {
    transition: color .2s ease-in-out, background .2s ease-in-out, border .2s ease-in-out;
    font-weight: bold;
    color: @font-colour-link;
}

p {
    margin: 0;
    padding: 0;

    line-height: @line-height;

    small {

    }

    strong {
        font-weight: bold;
    }

}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px 0;
    padding: 0;

    font-family: @font-family-heading;
    color: @font-colour-heading;
    line-height: @line-height;

    small {
        display: inline-block;
        &:before {
            content: ' ';
        }
    }

    > a, a > & {
        color: @font-colour-link;
        text-decoration: none;
        font-weight: inherit;
    }
}

h1 { font-size: @font-size-h1; }
h2 { font-size: @font-size-h2; }
h3 { font-size: @font-size-h3; }
h4 { font-size: @font-size-h4; }
h5 { font-size: @font-size-h5; }
h6 { font-size: @font-size-h6; }

blockquote {
    position: relative;
    margin: 0;
    padding: (@gutter * 2) 100px (@gutter * 2) 110px;
    border: none;
    font-family: @font-family-quote;
    font-size: @font-size * 3;

    &:before, &:after {
        color: @colour-primary;
        font-family: Georgia;
        font-size: 240px;
        position: absolute;
        display: inline-block;
        width: 98px;
        height: 98px;
        line-height: 1;
    }

    &:before {
            content: open-quote;
            top: @gutter;
            left: 0;
    }

    &:after {
        content: close-quote;
        bottom: @gutter;
        right: 0;
    }

    @media screen and (max-width: @screen-sm-max) {
        padding: (@gutter * 1.5) 70px;
        font-size: @font-size * 2;

        &:before, &:after {
            font-size: 160px;
            width: 65px;
            height: 65px;
            line-height: 1;
        }

        &:before {
            left: -(@gutter/2);
            top: @gutter/2;
        }

        &:after {
            right: -(@gutter/2);
            bottom: @gutter/2;
        }
    }

}
.blockquote-append {
    margin-top: -(@gutter);
    padding: 0 100px 0 110px;
    .author, .stars {
        display: block;
        font-size: @font-size * 1.2;
    }
    .author {
        font-family: @font-family;
    }
    .stars {
        color: gold;
    }
    @media screen and (max-width: @screen-sm-max) {
        padding: 0 70px;
        .author, .stars {
            font-size: @font-size;
        }
    }
}


ul, ol {
    margin: 0;
    padding: 0;

    li {
        margin-bottom: @line-height * @font-size;
        margin-left: (@line-height * @font-size);
    }
}

hr {
    border: none;
    border-top: @border-default;
}
